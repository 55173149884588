/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { forwardRef } from "react";
import { useGLTF } from "@react-three/drei";

const Thread = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF("./thread.glb");

  return (
    <group {...props} ref={ref} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.thread_spool.geometry}
        material={materials.material_0}
      />
    </group>
  );
});

useGLTF.preload("./thread.glb");

export default Thread;
