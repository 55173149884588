/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { MeshStandardMaterial } from "three";

export default function Toolbox(props) {
  const { nodes } = useGLTF("./toolbox.glb");
  const tangerineMaterial = new MeshStandardMaterial({ color: "#CE5E00" });

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Toolbox.geometry}
        material={tangerineMaterial}
      />
    </group>
  );
}

useGLTF.preload("./toolbox.glb");
