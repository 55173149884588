/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { forwardRef } from "react";
import { useGLTF } from "@react-three/drei";
import { MeshStandardMaterial } from "three";

const Keyboard = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF("./keyboard.glb");
  const customMaterial = new MeshStandardMaterial({ color: "skyblue" });

  return (
    <group {...props} ref={ ref } dispose={null}>
      <mesh
        geometry={nodes.KEY_ENTER.geometry}
        material={customMaterial}
        position={[-12.249, 9.854, 30.104]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.KEY_SPACE.geometry}
        material={customMaterial}
        // material={nodes.KEY_SPACE.material}
        position={[-21.07, 9.592, -12.5]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.KEY_X.geometry}
        material={customMaterial}
        position={[-0.07, 9.978, -31]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.KEY_Y.geometry}
        material={customMaterial}
        position={[-0.071, 9.981, -10]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.KEY_Z.geometry}
        material={customMaterial}
        position={[-0.07, 9.977, 11]}
        castShadow
        receiveShadow
      />
      <mesh
        geometry={nodes.KEYBOARD.geometry}
        material={customMaterial}
        position={[-10.632, -0.258, 0.5]}
        castShadow
        receiveShadow
      />
    </group>
  );
});

useGLTF.preload("./keyboard.glb");

export default Keyboard;
